import * as React from 'react';
import classNames from 'classnames';
import { TranslationGetter } from '@wix/editor-elements-types';
import { IResponsiveBoxProps } from '../../../Container.types';
import ResponsiveContainer from '../../../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import commonStyles from '../../shared/common.scss';
import {
  ARIA_LABEL_DEFAULT,
  ARIA_LABEL_KEY,
  ARIA_LABEL_NAMESPACE,
} from '../../shared/constants';
import { getAriaAttributes } from '../../../../../core/commons/a11y';
import styles from './ResponsiveBox.scss';

const getAriaLabel = (translate: TranslationGetter | undefined) => {
  return translate
    ? translate(ARIA_LABEL_NAMESPACE, ARIA_LABEL_KEY, ARIA_LABEL_DEFAULT)
    : ARIA_LABEL_DEFAULT;
};

// This container is used in responsive site
const ResponsiveBox: React.FC<IResponsiveBoxProps> = ({
  id,
  containerProps,
  containerRootClassName = '',
  children,
  role,
  onClick,
  onDblClick,
  onMouseEnter,
  onMouseLeave,
  hasPlatformClickHandler,
  supportPinnedToContainer,
  translate,
  a11y = {},
  ariaAttributes = {},
  tabIndex,
}) => {
  const { 'aria-label-interactions': ariaLabelInteractions, ...a11yAttr } =
    a11y;

  if (ariaLabelInteractions) {
    a11yAttr['aria-label'] = getAriaLabel(translate);
  }

  return (
    <div
      id={id}
      {...a11yAttr}
      {...getAriaAttributes({ ...ariaAttributes, tabIndex, role })}
      className={classNames(styles.root, containerRootClassName, {
        [commonStyles.clickable]: hasPlatformClickHandler,
      })}
      onDoubleClick={onDblClick}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.bg} />
      {supportPinnedToContainer ? (
        children() // contains: (1) ResponsiveContainer with relative children, (2) DynamicStructureContainer with pinned children
      ) : (
        <ResponsiveContainer {...containerProps}>
          {children}
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ResponsiveBox;
